<template>
	<div class="prose max-w-none antialiased">
		<DefaultHeader :show-menu="showMenu" :show-campaign="showCampaign" />

		<div class="lg:min-h-[670px] xl:min-h-[620px]">
			<slot />
		</div>

		<FootersDefaultFooter v-if="showFooter" />

		<LazyModalsMessageModal v-if="showMessage" />
	</div>
</template>

<script setup>
import DefaultHeader from '@/components/headers/DefaultHeader.vue'
import { useCarbarStore } from '@/stores/carbar'
import { useCampaignsStore } from '@/stores/campaigns'

/*******
 * Layout settings
 *
 * To disable the footer per page, use definePageMeta({layoutSettings: {showFooter: false}})
 * To disable the menu per page, use definePageMeta({layoutSettings: {showMenu: false}})
 *
 *******/

// we need to call this here to trigger the hydration of the carbar-Au-Auth-Store within the initial setups, otherwise
// we get an "onUnmounted" warning from pinia's persisted state. See DE-5187
isLoggedIn()
checkAuthLoginVersion()

const route = useRoute()
const runtimeConfig = useRuntimeConfig()

const carbar = useCarbarStore()

const showFooter = computed(() => route.meta.layoutSettings?.showFooter ?? true)
const showMenu = computed(() => route.meta.layoutSettings?.showMenu ?? true)
const showCampaign = computed(
	() => route.meta.layoutSettings?.showCampaign ?? true
)

onBeforeMount(() => {
	useTrustMary()
	useMicrosoftClarity()
	useDealfront()
	useRecaptcha()

	try {
		useLazyFetchForApi(runtimeConfig.public.API_URL + '/site-meta-data', {
			lazy: true,
			onResponse({ response }) {
				carbar.vehicleCount.subscription =
					response._data.data.vehicleCount.subscription
				carbar.disabledDates =
					response._data.data.deliveryBlockOut ?? {}

				const campaigns = useCampaignsStore()
				campaigns.campaigns = response._data.data.campaign ?? {}
				campaigns.checkCampaignStatus()
			},
		})
	} catch (e) {
		// do nothing
	}
})

const showMessage = computed(() => {
	if (!carbar || !carbar.message) {
		return false
	}

	return carbar.message.error || carbar.message.success
})

if (!route.meta.disableGenericCanonical) {
	useHead(() => ({
		// Tile and meta description needs to be declared here, it acts
		// as a fallback for other pages to avoid displaying undefined
		// value in title and meta tag due to reactivity for certain pages ie. vehicle page.
		title: 'Subscribe Your Next Car | carbar Australia',
		meta: [
			{
				name: 'description',
				content:
					'Subscribe to your next car online. Sell your car without haggling, get an instant valuation.',
			},
		],
		link: [
			{
				rel: 'canonical',
				href: runtimeConfig.public.APP_URL + route.path,
			},
		],
		htmlAttrs: {
			lang: 'en-AU',
		},
	}))
}
</script>
